<script setup lang="ts">
    import { onMounted, reactive, ref } from "vue";
    import { MaskInputOptions } from "maska";
    import useVuelidate from "@vuelidate/core";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import { vMaska } from "maska/vue";
    import { formatPrice } from "@/composables/formatter";

    const fieldModel = defineModel<number>({ required: true });
    const { rules = {}, canEdit } = defineProps<{
        rules?: unknown;
        canEdit: boolean;
    }>();

    const modelRef = ref();
    const v$ = useVuelidate({ modelRef: rules }, { modelRef });

    onMounted(() => {
        const value = fieldModel.value / 100;
        modelRef.value = value.toString().replace(".", ",");
    });

    const moneyMask = reactive<MaskInputOptions>({
        number: {
            locale: "nl",
            fraction: 2,
            unsigned: true,
        },
        onMaska: (event) => {
            const parsedValue = Math.round(parseFloat(event.unmasked) * 100);
            if (isNaN(parsedValue)) {
                fieldModel.value = 0;
            } else {
                fieldModel.value = parsedValue;
            }
        },
    });
</script>

<template>
    <v-text-field
        v-if="canEdit"
        v-model="modelRef"
        v-maska="moneyMask"
        :error-messages="getVuelidateErrors(v$.modelRef.$errors)"
        density="compact"
        hide-details="auto"
        single-line
        variant="outlined"
        @focusout="v$.modelRef.$validate"
    >
        <template #prepend-inner>€</template>
    </v-text-field>
    <template v-else>
        <span>
            {{ formatPrice(fieldModel ?? 0) }}
        </span>
    </template>
</template>

<style scoped></style>
