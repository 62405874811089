import { RouteRecordRaw } from "vue-router";
import Index from "@/views/timeslots/Index.vue";

export const timeslotRoutes: RouteRecordRaw[] = [
    {
        path: "/timeslots",
        component: Index,
        name: "timeslot-index",
        meta: {
            requiresAuth: true,
            onProd: true,
            roles: ["ROLE_ADMIN"],
        },
    },
];
