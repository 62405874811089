<script setup lang="ts">
    import { OrderDetails } from "@/models/OrderDetails";
    import { VChip } from "vuetify/components";

    const { method, ...props } = defineProps<{
        method: OrderDetails["paymentMethod"];
    }>();
</script>

<template>
    <v-chip
        v-if="method.type == 'online'"
        v-bind="props"
        prepend-icon="mdi-web"
    >
        {{ method.label }}
    </v-chip>
    <v-chip
        v-if="method.type == 'card'"
        v-bind="props"
        prepend-icon="mdi-credit-card"
    >
        {{ method.label }}
    </v-chip>
    <v-chip v-if="method.type == 'cash'" v-bind="props" prepend-icon="mdi-cash">
        {{ method.label }}
    </v-chip>
    <v-chip
        v-if="method.type == 'invoice'"
        v-bind="props"
        prepend-icon="mdi-text-box"
    >
        {{ method.label }}
    </v-chip>
</template>

<style scoped></style>
