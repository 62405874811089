import ApiService from "@/services/ApiService";
import { OrderDetails } from "@/models/OrderDetails";
import { OrderDTO } from "@/store/orderCreation/types";

import type { PartialDeep } from "type-fest";

const apiService = ApiService;

const orderService = {
    getOrderById: (id: number) => {
        return apiService.get(`/api/v1/order/${id}`);
    },
    getOrderPermissionsById: (id: number) => {
        return apiService.get(`/api/v1/order/${id}/edit`);
    },
    getOrders: (search: string, skip: number, top: number, filter: string) => {
        const params = new URLSearchParams();
        params.append("$skip", String(skip));
        params.append("$top", String(top));
        if (search) {
            params.append("search", search);
        }
        if (filter) {
            params.append("$filter", filter);
        }
        // Temporarily hard code the orderby until we implement user configurable sorting
        params.append("$orderby", "createdAt desc");

        return apiService.get("/api/v1/order", { params });
    },
    updateSumupTransaction: async (sumupTXid: string) => {
        return await apiService.post("/webhooks/sumup/transactionupdate", {
            sumupId: sumupTXid,
        });
    },
    updateOrderById: async (
        id: number,
        patch: { data: PartialDeep<OrderDTO> },
    ) => {
        return await apiService.patch(`/api/v1/order/${id}`, patch);
    },
    getPrintOrders: (search: string, filter: string) => {
        const params = new URLSearchParams();
        params.append("search", search);

        params.append("$skip", String(0));
        params.append("$top", String(100));
        if (filter) {
            params.append("$filter", filter);
        }

        return apiService.get("/api/v1/order/print", { params });
    },
    alterOrderState: (
        id: number,
        state?: Partial<OrderDetails["state"] | { paid: boolean }>,
    ) => {
        return apiService.patch(`/api/v1/order/${id}`, {
            state: state,
        });
    },
};

export default orderService;
