<script lang="ts" setup>
    import { DeliveryFullfilment, OrderDetails } from "@/models/OrderDetails";
    import { OrderContext } from "@/models/OrderPermissions";
    import {
        deliveryDay,
        deliverySlot,
        OrderDTO,
    } from "@/store/orderCreation/types";
    import { computed, ref, watch } from "vue";
    import { klona } from "klona";
    import useVuelidate from "@vuelidate/core";
    import { required } from "@/composables/dutchVuelidate";
    import orderService from "@/services/OrderService";
    import type { PartialDeep } from "type-fest";

    const dialogState = defineModel<boolean>({ required: true });

    const { order, context } = defineProps<{
        order: OrderDetails;
        context: OrderContext;
    }>();

    const emits = defineEmits<{
        success: [];
    }>();

    const day = ref<Date>();
    const timeslot = ref<number | undefined>();
    const areagroup = ref<number | undefined>();

    const convertDay = (day: deliveryDay) => {
        const label = new Date(day.date).toLocaleDateString("nl-NL", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        } as Intl.DateTimeFormatOptions);

        return label.charAt(0).toUpperCase() + label.slice(1);
    };

    const convertTime = (value: deliverySlot | number) => {
        // In the case the timeslot is deleted from the list of available timeslots
        //  return an empty string to prevent the v-select from crashing
        if (typeof value == "number") return "";

        return value.start.slice(0, -3) + " - " + value.end.slice(0, -3);
    };

    watch(dialogState, () => {
        // When the dialog is enabled, find the selected day
        timeslot.value = order.timeslot.id;
        day.value = new Date(
            context.timeslots.find((item) => {
                return !!item.slots.find((slot) => {
                    return slot.id == timeslot.value;
                });
            })?.date ?? "",
        );

        error.value = undefined;

        // If we have a delivery order, we have info about the current areagroup
        if (order.deliver) {
            areagroup.value = order.fulfillment.areaGroup.id;
        }
    });

    const getAvailableDays = computed(() => {
        const allowedAreaGroups = context.allowedTimeslots.reduce(
            (acc, areagroups) => {
                return acc.add(areagroups.areaGroup);
            },
            new Set<number>([]),
        );

        const availableSlotIds: Set<number> = context.areaGroups.reduce(
            (acc, group) => {
                if (!allowedAreaGroups.has(group.id)) {
                    return acc;
                }

                return acc.union(
                    new Set(
                        context.allowedTimeslots.find(
                            (set) => set.areaGroup == group.id,
                        )?.timeslots ?? [],
                    ),
                );
            },
            new Set<number>([]),
        );

        const days = klona(context.timeslots);

        for (const day of days) {
            day.slots = day.slots.filter((slot) => {
                return availableSlotIds.has(slot.id);
            });
        }

        // Clean the days without any available timeslots
        return days.filter((day) => {
            return day.slots.length > 0;
        });
    });

    const availableTimeslots = computed(() => {
        return (
            getAvailableDays.value.find((item) => {
                return new Date(item.date).getTime() == day.value?.getTime();
            })?.slots ?? []
        );
    });

    const availableAreaGroups = computed(() => {
        const allowedAreaGroups = context.allowedTimeslots.reduce(
            (acc, areagroupSet) => {
                if (areagroupSet.timeslots.length == 0) {
                    return acc;
                }
                if (areagroupSet.timeslots.includes(timeslot.value ?? -1)) {
                    return acc.add(areagroupSet.areaGroup);
                }

                return acc;
            },
            new Set<number>([]),
        );

        return context.areaGroups.filter((group) => {
            return allowedAreaGroups.has(group.id);
        });
    });

    const areaGroupIntegrityCheck = () => {
        // Find the areagroups that contains the selected timeslot
        const allowedAreaGroups = context.allowedTimeslots.reduce(
            (acc, areagroups) => {
                if (areagroups.timeslots.includes(timeslot.value ?? -1)) {
                    return acc.add(areagroups.areaGroup);
                }

                return acc;
            },
            new Set<number>([]),
        );

        if (
            allowedAreaGroups.has(
                (order.fulfillment as DeliveryFullfilment).areaGroup.id,
            )
        ) {
            areagroup.value = (
                order.fulfillment as DeliveryFullfilment
            ).areaGroup.id;
            return;
        }

        areagroup.value = undefined;
    };

    const areaGroupChanged = computed(() => {
        return (
            areagroup.value !=
            (order.fulfillment as DeliveryFullfilment).areaGroup.id
        );
    });

    const v$ = useVuelidate(
        {
            day: {
                required,
            },
            timeslot: {
                required,
            },
            areagroup: {
                required,
            },
        },
        {
            day,
            timeslot,
            areagroup,
        },
        {
            $stopPropagation: true,
        },
    );

    const error = ref<string | undefined>();
    const processing = ref<boolean>(false);

    const apply = async () => {
        error.value = undefined;
        processing.value = true;
        if (!(await v$.value.$validate())) {
            processing.value = false;
            return;
        }

        const patch = {
            data: {
                fulfillment: {
                    timeslot: timeslot.value,
                    data: {
                        areaGroup: areagroup.value,
                    },
                },
            } as PartialDeep<OrderDTO>,
        };

        try {
            await orderService.updateOrderById(order.id, patch);
            emits("success");
        } catch (e) {
            error.value =
                "Kan bewerking niet opslaan, mogelijk is het tijdvak (inmiddels) bezet";
        } finally {
            processing.value = false;
        }
    };
</script>

<template>
    <v-dialog v-model="dialogState" max-width="500">
        <v-card class="rounded-lg">
            <v-card-title class="bg-info">Tijdvak aanpassen</v-card-title>
            <v-card-text>
                <div class="text-subtitle-1 text-medium-emphasis">
                    Kies een bezorgmoment
                </div>
                <v-row class="pb-5">
                    <v-col cols="12" md="6">
                        <v-select
                            v-model="day"
                            :item-title="convertDay"
                            :items="getAvailableDays"
                            density="compact"
                            hide-details="auto"
                            item-value="date"
                            placeholder="Bezorgmoment"
                            variant="outlined"
                            @blur="v$.day.$validate"
                            @update:model-value="timeslot = undefined"
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-select
                            v-model="timeslot"
                            :disabled="!day"
                            :item-title="convertTime"
                            :items="availableTimeslots"
                            density="compact"
                            hide-details="auto"
                            item-value="id"
                            placeholder="Tijd"
                            variant="outlined"
                            @blur="v$.timeslot.$validate"
                            @update:model-value="areaGroupIntegrityCheck"
                        />
                    </v-col>
                </v-row>
                <div
                    v-if="
                        areagroup !=
                        (order.fulfillment as DeliveryFullfilment).areaGroup.id
                    "
                    class="text-subtitle-1 text-medium-emphasis"
                >
                    Wijkgroep aanpassen
                </div>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="areagroup"
                            :disabled="!timeslot"
                            :items="availableAreaGroups"
                            density="compact"
                            hide-details="auto"
                            item-title="name"
                            item-value="id"
                            placeholder="Wijkgroep"
                            variant="outlined"
                            @blur="v$.areagroup.$validate"
                        />
                    </v-col>
                </v-row>
                <v-alert
                    v-if="areaGroupChanged"
                    class="mt-3"
                    density="compact"
                    type="warning"
                    >Wijkgroep is aangepast
                </v-alert>
                <v-alert
                    v-if="error"
                    density="compact"
                    class="mt-3"
                    type="error"
                >
                    {{ error }}
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col>
                        <v-btn color="info" @click="dialogState = false">
                            Annuleren
                        </v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col>
                        <v-btn color="info" :loading="processing" @click="apply"
                            >Toepassen</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
