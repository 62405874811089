<script setup lang="ts">
    import { VAlert } from "vuetify/components";
    import { useDisplay } from "vuetify";
    import { useRoute, useRouter } from "vue-router";
    import { ref } from "vue";
    import authenticationService from "@/services/AuthenticationService";
    import axios from "axios";
    import useVuelidate from "@vuelidate/core";
    import PasswordField from "@/components/authentication/PasswordField.vue";

    // Load the smAndDown display breakpoint
    const { smAndDown } = useDisplay();
    const router = useRouter();
    const route = useRoute();

    // Alert data on top of the page
    const alert = ref<{
        type: VAlert["type"];
        message: string;
    }>({
        type: undefined,
        message: "",
    });

    // Is the form loading right now?
    const loading = ref<boolean>();

    // New password
    const password = ref<string>("");

    // Require to fetch the validations of child components
    const v$ = useVuelidate();

    const submit = async () => {
        loading.value = true;

        // Reset alert
        alert.value = {
            type: undefined,
            message: "",
        };

        // Validate form
        v$.value.$touch();
        if (v$.value.$invalid) {
            loading.value = false;
            return;
        }

        try {
            await authenticationService.resetPassword(
                route.query.token as string,
                password.value,
            );

            await router.push({
                name: "login",
                query: { resetPassword: "true" },
            });
        } catch (e) {
            if (axios.isAxiosError(e)) {
                alert.value = {
                    type: "error",
                    message: "Er is iets misgegaan. Probeer het later opnieuw.",
                };
                if (e?.response?.data["error_code"] == -1213) {
                    alert.value = {
                        type: "error",
                        message:
                            "De link is niet (meer) geldig. Vraag een nieuwe link aan.",
                    };
                }
            }
        } finally {
            loading.value = false;
        }
    };
</script>

<template>
    <v-expand-transition>
        <span v-show="alert.message !== ''">
            <v-alert
                theme="light"
                :type="alert.type"
                :max-width="smAndDown ? undefined : 500"
            >
                {{ alert.message }}
            </v-alert>
            <br />
        </span>
    </v-expand-transition>

    <v-card class="rounded-lg" :width="smAndDown ? undefined : 500">
        <v-card-title>Wachtwoord opnieuw instellen</v-card-title>
        <v-card-text>
            <v-form @keyup.enter="submit">
                <password-field v-model="password" />
                <br />
            </v-form>
            <v-row>
                <v-col>
                    <v-btn
                        color="primary"
                        variant="tonal"
                        @click="router.push('login')"
                        >Naar inloggen
                    </v-btn>
                </v-col>
                <v-spacer />
                <v-col>
                    <v-btn
                        color="primary"
                        variant="flat"
                        :loading="loading"
                        @click="submit"
                        >Wachtwoord wijzigen
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
