<template>
    <v-container fluid>
        <globy-app-bar title="Bestellingen">
            <template #actions>
                <v-btn icon="mdi-reload" @click="debounce(LoadOrders, 1)()" />
            </template>
        </globy-app-bar>
        <filter-card class="mb-2" @on-print="printDialog = true" />
        <print-order-bulk-dialog v-model="printDialog" />
        <v-card>
            <v-card-title>
                <span :class="{ 'flex-column': smAndDown }" class="d-flex pa-3">
                    <h1 class="align-self-center">Bestellingen</h1>
                    <v-chip
                        v-if="filterStore.getFilterEnabled"
                        class="ma-5 align-self-center"
                        color="warning"
                        density="default"
                        prepend-icon="mdi-exclamation"
                        text="Filters actief"
                        variant="flat"
                    />
                    <v-spacer />
                    <v-text-field
                        v-model="filterStore.filter.search"
                        density="comfortable"
                        flat
                        label="Zoeken"
                        prepend-inner-icon="mdi-magnify"
                        single-line
                        variant="solo-filled"
                        hide-details
                    />
                </span>
            </v-card-title>
            <v-card-text>
                <span class="d-flex flex-column">
                    <v-data-table-server
                        :headers="headers"
                        :items="orders"
                        :items-length="totalResultCount"
                        :items-per-page="itemsPerPage"
                        :items-per-page-options="itemsPerPageOptions"
                        :loading="loading"
                        :loading-text="'Bestellingen aan het ophalen...'"
                        :no-data-text="'Geen bestellingen gevonden :('"
                        :page="currentPage"
                        :show-current-page="true"
                        hover
                        @update:options="updateTableProps"
                    >
                        <template #item="{ item }">
                            <template v-if="!smAndDown">
                                <overview-line
                                    :item="item"
                                    class="row-pointer"
                                    @click="RowClick(item.id)"
                                />
                            </template>
                            <template v-else>
                                <mobile-overview-line
                                    :item="item"
                                    class="row-pointer"
                                    @click="RowClick(item.id)"
                                />
                            </template>
                        </template>
                    </v-data-table-server>
                </span>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script lang="ts" setup>
    import { computed, ref, watch } from "vue";
    import Router from "@/router";
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import orderService from "@/services/OrderService";
    import { AxiosResponse } from "axios";

    import { useDisplay } from "vuetify";
    import FilterCard from "@/components/orderOverview/FilterCard.vue";
    import { useOverviewFilterStore } from "@/store/orderOverview";
    import PrintOrderBulkDialog from "@/components/dialogs/printing/PrintOrderBulkDialog.vue";
    import { OrderDetails } from "@/models/OrderDetails";
    import { VDataTableServer } from "vuetify/components/VDataTable";
    import OverviewLine from "@/components/orderOverview/OverviewLine.vue";
    import MobileOverviewLine from "@/components/orderOverview/MobileOverviewLine.vue";

    const { smAndDown } = useDisplay();
    const headers = computed<VDataTableServer["headers"]>(() => {
        if (smAndDown.value) {
            return [
                {
                    title: "Bestelling",
                },
            ];
        }

        return [
            {
                title: "Naam",
                width: "2rem",
            },
            {
                title: "Tijdvak",
            },
            {
                title: "Wijk",
            },
            {
                title: "Bestelling",
            },
            {
                title: "Betaling",
            },
        ];
    });
    const orders = ref<OrderDetails[]>([]);
    const printDialog = ref<boolean>(false);
    const totalResultCount = ref<number>(0);
    const currentPage = ref<number>(1);
    const itemsPerPage = ref<number>(10);
    const itemsPerPageOptions = ref<{ title: string; value: number }[]>([
        {
            title: "5",
            value: 5,
        },
        {
            title: "10",
            value: 10,
        },
        {
            title: "25",
            value: 25,
        },
        {
            title: "50",
            value: 50,
        },
        {
            title: "100",
            value: 100,
        },
    ]);
    const filterStore = useOverviewFilterStore();
    const loading = ref<boolean>(true);

    const LoadOrders = async () => {
        loading.value = true;
        const skip = (currentPage.value - 1) * itemsPerPage.value;
        orderService
            .getOrders(
                filterStore.filter.search,
                skip,
                itemsPerPage.value,
                filterStore.getODataFilter,
            )
            .then((response: AxiosResponse) => {
                orders.value = response.data.data;
                totalResultCount.value =
                    response.data.metadata.totalResultCount;
                loading.value = false;
            });
    };

    const updateTableProps = (props: {
        page: number;
        itemsPerPage: number;
    }) => {
        itemsPerPage.value = props.itemsPerPage;
        currentPage.value = props.page;
        LoadOrders();
    };

    watch(filterStore.filter, () => {
        debounce(LoadOrders, 500)();
    });

    function RowClick(id: number) {
        const url = `/orders/${id}`;
        Router.push(url);
    }

    let timer: ReturnType<typeof setTimeout> | undefined;
    // _ is unused in this function, but is used in the triggering function. Typescript does not understand this.
    // eslint-disable-next-line no-unused-vars
    const debounce = function (fn: () => void, wait: number) {
        return function () {
            if (timer) {
                clearTimeout(timer); // clear any pre-existing timer
            }
            timer = setTimeout(() => {
                fn(); // call the function if time expires
            }, wait);
        };
    };
</script>

<style scoped>
    .row-pointer {
        cursor: pointer;
    }
</style>
