<script lang="ts" setup>
    import { OrderDetails } from "@/models/OrderDetails";
    import { usePinTerminal } from "@/composables/integrations/usePinTerminal";

    const modelValue = defineModel<boolean>();

    const { order } = defineProps<{
        order: OrderDetails;
    }>();

    const { startPayment } = usePinTerminal(order);
</script>

<template>
    <v-dialog v-model="modelValue" max-width="500px" persistent>
        <v-card class="rounded-lg">
            <v-card-title class="bg-info">
                <span class="headline">Bestelling pinnen</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <p>
                                Deze bestelling moet nog gepind worden. Klik op
                                de knop hieronder om betaling te starten. Let er
                                goed op dat de betaling lukt!
                                <br />
                                <br />
                                Lukt de betaling niet? Probeer het nog eens.
                                Lukt het dan nog niet? Neem dan contact op met
                                de beheersite noodlijn.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="modelValue = false">Annuleren</v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="
                                    modelValue = false;
                                    startPayment();
                                "
                                >Afrekenen</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
