<script lang="ts" setup>
    import lodash from "lodash";
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import { useTimeslots } from "@/composables/remote/useTimeslots";
    import { formatDate } from "@/composables/formatter";
    import timeslotService, {
        FulfillmentSlot,
        Timeslot,
    } from "@/services/TimeslotService";
    import { ref, unref, watch } from "vue";
    import { klona } from "klona";
    import { getPatchObject } from "@/composables/requestHelpers";

    const { loading, data, error, loadData } = useTimeslots();

    const formatTime = (value: string) => {
        return unref(value).slice(-8, -3);
    };

    const timeslots = ref<Timeslot[]>();

    watch(loading, () => {
        timeslots.value = klona(data.value);
    });

    const submitChanges = async () => {
        const promises = [];

        if (!timeslots.value || !data.value) {
            // No changes to submit as data is not loaded yet
            return;
        }

        // For each changed timeslot, push to the API
        for (const i in timeslots.value as Timeslot[]) {
            for (const j in timeslots.value[i].slots) {
                const newSlot = timeslots.value[i].slots[j];
                const oldSlot = data.value[i].slots[j];

                if (newSlot.id !== oldSlot.id) {
                    throw new Error("Old and new slot are not the same");
                }

                const diff = getPatchObject(
                    newSlot,
                    oldSlot,
                ) as Partial<FulfillmentSlot>;
                // Skip if there are no differences
                if (lodash.isEmpty(diff)) {
                    continue;
                }

                // Patch using the API
                promises.push(timeslotService.update(oldSlot.id, diff));
            }
        }

        // Wait for all promises to resolve
        await Promise.all(promises);

        // Reload the web page
        loadData();
    };
</script>

<template>
    <v-container fluid>
        <GlobyAppBar title="Tijdvakken aanpassen">
            <template #actions>
                <v-btn
                    variant="tonal"
                    prepend-icon="mdi-pencil"
                    class="mr-4"
                    @click="submitChanges"
                >
                    Opslaan
                </v-btn>
            </template>
        </GlobyAppBar>
        <template v-if="loading"> </template>
        <template v-else-if="error">
            <v-alert type="error"
                >Er is iets fout gegaan bij het ophalen van de ingestelde
                tijdvakken.</v-alert
            >
        </template>
        <template v-else>
            <template
                v-for="deliveryday in timeslots as Timeslot[]"
                :key="deliveryday.day"
            >
                <v-card class="mb-3">
                    <v-card-title>
                        {{ lodash.capitalize(formatDate(deliveryday.day)) }}
                    </v-card-title>
                    <v-card-text>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="width: 1em">
                                        Start
                                    </th>
                                    <th rowspan="2">Eind</th>
                                    <th class="text-center" colspan="3">
                                        Actief
                                    </th>
                                </tr>
                                <tr>
                                    <th style="width: 3em">Globaal</th>
                                    <th style="width: 3em">Bestelsite</th>
                                    <th style="width: 3em">Beheeromgeving</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="slot in deliveryday.slots"
                                    :key="slot.id"
                                >
                                    <td>
                                        {{ formatTime(slot.start) }}
                                    </td>
                                    <td>
                                        {{ formatTime(slot.end) }}
                                    </td>
                                    <td>
                                        <v-checkbox-btn v-model="slot.active" />
                                    </td>
                                    <td>
                                        <v-checkbox-btn
                                            v-model="slot.pos"
                                            :disabled="!slot.active"
                                        />
                                    </td>
                                    <td>
                                        <v-checkbox-btn
                                            v-model="slot.backoffice"
                                            :disabled="!slot.active"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </template>
        </template>
    </v-container>
</template>

<style scoped></style>
