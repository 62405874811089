<script setup lang="ts">
    import { useDisplay } from "vuetify";

    const { smAndDown } = useDisplay();
</script>

<template>
    <v-app theme="dark">
        <div
            id="bgContainer"
            class="w-100 d-flex justify-center fill-height flex-column"
        >
            <v-main>
                <span
                    class="w-100 d-flex justify-center fill-height flex-column pa-2"
                >
                    <span
                        class="align-self-center"
                        :class="{ 'w-100': smAndDown }"
                    >
                        <router-view />
                    </span>
                </span>
            </v-main>
        </div>
    </v-app>
</template>

<style scoped lang="scss">
    @use "sass:map";
    @import "node_modules/vuetify/settings";

    /* src: https://css-tricks.com/apply-a-filter-to-a-background-image/ */
    #bgContainer::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("/src/assets/authenticationBackground.png");
        background-size: cover;
        filter: brightness(0.2) blur(10px);
    }

    /* These paddings are necesary to properly handle notches and the statusbar on Iphones */
    #bgContainer {
        padding-bottom: env(safe-area-inset-bottom);
        padding-top: env(safe-area-inset-top);
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
</style>
