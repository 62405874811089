<script setup lang="ts">
    import { useInstanceStore } from "@/store/instance";
    import { useLayoutStore } from "@/store/layout";
    import { computed } from "vue";
    import { useAuthStore } from "@/store/auth";
    import { MenuItem, MenuLink } from "@/components/common/sidebar/types";
    import SubMenu from "@/components/common/sidebar/SubMenu.vue";
    import type { SubMenu as SubMenuType } from "@/components/common/sidebar/types";
    import NavLink from "@/components/common/sidebar/NavLink.vue";

    import menuConfig from "@/config/menu.yaml";

    const instanceStore = useInstanceStore();

    const layoutStore = useLayoutStore();
    const userStore = useAuthStore();

    const menuItems = computed<SubMenuType["items"]>(() => {
        // Determines if the item should be selectable by a user
        const itemAllowed = (item: MenuLink) => {
            if (item.allowed_roles) {
                return item.allowed_roles.some((role) =>
                    userStore.hasRole(role),
                );
            }
            return true;
        };

        // Takes a MenuItem and filters out all items that are not allowed according to
        // the conditions set in the itemAllowed function
        // If it concerns a SubMenu, it will recursively filter out it's children
        // if that means the SubMenu is empty, undefined is returned.
        const filterItem = (item: MenuItem) => {
            // [Base] Regular item case
            if ("route" in item) {
                return itemAllowed(item as MenuLink) ? item : undefined;
            }
            // [Recursive] Submenu case
            else {
                const items: SubMenuType["items"] = {};
                for (const itemKey in item.items) {
                    const filteredItem = filterItem(item.items[itemKey]);
                    if (filteredItem) {
                        items[itemKey] = filteredItem;
                    }
                }
                if (Object.keys(items).length === 0) {
                    return undefined;
                }

                return {
                    ...item,
                    items,
                } as SubMenuType;
            }
        };

        const sidebar = menuConfig.sidebar as { [key: string]: MenuItem };

        const result: SubMenuType["items"] = {};
        for (const key in sidebar) {
            const item = sidebar[key];
            const filteredItem = filterItem(item);
            if (filteredItem) {
                result[key] = filteredItem;
            }
        }

        return result;
    });
</script>

<template>
    <v-navigation-drawer
        id="sidenavigation"
        v-model="layoutStore.navigationDrawer"
        theme="dark"
    >
        <v-list dense nav>
            <v-list-item>
                {{ instanceStore.name }}
            </v-list-item>
            <v-list-item>
                <template #prepend>
                    <v-icon size="large"> mdi-account</v-icon>
                </template>
                <v-list-item-title
                    >{{ userStore.firstName + " " + userStore.lastName }}
                </v-list-item-title>
            </v-list-item>
            <v-divider class="mb-2" />

            <sub-menu :items="menuItems" />
        </v-list>

        <template #append>
            <v-list>
                <nav-link
                    icon="mdi-exit-to-app"
                    text="Uitloggen"
                    to="/logout"
                />
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<style scoped>
    /* These paddings are necessary to properly handle notches and the statusbar on Iphones */
    #sidenavigation {
        padding-top: env(safe-area-inset-top);
        padding-left: env(safe-area-inset-left);
        padding-bottom: env(safe-area-inset-bottom);
    }
</style>
