// This generic function is reaaally hard to get right in typescript without the right types on the underscore package.
// So for now, this composable will have to do without proper typescript support.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import _ from "underscore";

// Note that removing objects from arrays is currently not supported by this function.
/**
 * This function takes two objects and returns a patch object that can be used to update the old object to the new object.
 * @param oldEntity
 * @param newEntity
 */
export const getPatchObject = (oldEntity, newEntity) => {
    const patchObject = diff(oldEntity, newEntity);

    return cleanObject(patchObject);
};

/**
 * This function takes an object and removes all empty objects and empty arrays from it.
 * @param target
 */
export const cleanObject = (target) => {
    const copy = JSON.parse(JSON.stringify(target));

    for (const key in copy) {
        if (_.isObject(copy[key])) {
            if (_.size(copy[key]) == 0) {
                delete copy[key];
            } else {
                copy[key] = cleanObject(copy[key]);
                if (_.size(copy[key]) == 0) {
                    delete copy[key];
                }
            }
        }
    }

    return copy;
};

// src: https://stackoverflow.com/questions/8572826/generic-deep-diff-between-two-objects
function diff(a, b) {
    const r = {};
    _.each(a, function (v, k) {
        if (b[k] === v) return;
        // but what if it returns an empty object? still attach?
        r[k] = _.isObject(v) ? diff(v, b[k]) : v;
    });
    return r;
}
