import ApiService from "@/services/ApiService";

const apiService = ApiService;

export interface Timeslot {
    day: Date;
    slots: FulfillmentSlot[];
}

export interface FulfillmentSlot {
    id: number;
    start: string;
    end: string;
    alert: unknown; // TODO: Add alert type when alert is needed
    active: boolean;
    pos: boolean;
    backoffice: boolean;
}

const timeslotService = {
    getAll: async () => {
        return apiService.get("/api/v1/timeslots").then((response) => {
            const timeslots = response.data as Timeslot[];
            for (const timeslot of timeslots) {
                timeslot.day = new Date(timeslot.day);
            }

            // Sort the dates
            timeslots.sort((a, b) => (a.day < b.day ? -1 : 1));

            // Sort the timeslots. Javascript is funny because it thinks 10:00 is smaller than 9:00.
            for (const timeslot of timeslots) {
                timeslot.slots.sort((a, b) => {
                    const starta =
                        parseInt(a.start.slice(-8, -6)) * 100 +
                        parseInt(a.start.slice(-5, -3));
                    const startb =
                        parseInt(b.start.slice(-8, -6)) * 100 +
                        parseInt(b.start.slice(-5, -3));
                    return starta < startb ? -1 : 1;
                });
            }

            return timeslots as Timeslot[];
        });
    },
    update: async (id: number, patch: Partial<FulfillmentSlot>) => {
        return apiService.patch(`/api/v1/timeslots/${id}`, patch);
    },
};

export default timeslotService;
