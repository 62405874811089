export const formatPrice = (value: number): string => {
    return "€ " + (value / 100).toFixed(2).replaceAll(".", ",");
};

export const formatIntlTelephone = (value: string): string => {
    return "+31" + value.slice(1).replaceAll(" ", "").replaceAll("-", "");
};

export const formatDate = (value: Date): string => {
    const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    } as Intl.DateTimeFormatOptions;

    return value.toLocaleDateString("nl-NL", dateOptions);
};
