<script setup lang="ts">
    import { getErrors, required } from "@/composables/dutchVuelidate";
    import { helpers, minLength, sameAs } from "@vuelidate/validators";
    import { computed, ref } from "vue";
    import useVuelidate, { ValidationArgs } from "@vuelidate/core";

    const password = defineModel<string>();
    const passwordConfirmation = ref("");

    const showPassword = ref(false);

    const props = withDefaults(
        defineProps<{ requireConfirmation?: boolean }>(),
        {
            requireConfirmation: true,
        },
    );

    const lengthRequirement = ref(8);

    const rules = computed(() => {
        const result: ValidationArgs<{
            password: string;
            passwordConfirmation: string;
        }> = {
            password: {
                required,
                minLength: minLength(lengthRequirement),
                minOneUppercase: helpers.regex(/^(?=.*[A-Z]).*$/),
                minOneLowercase: helpers.regex(/^(?=.*[a-z]).*$/),
                minOneNumber: helpers.regex(/^(?=.*[0-9]).*$/),
            },
            passwordConfirmation: {},
        };
        if (props.requireConfirmation) {
            result.passwordConfirmation = {
                required,
                sameAs: sameAs(password),
            };
        }

        return result;
    });

    const v$ = useVuelidate(rules, { password, passwordConfirmation });

    const passwordErrors = computed(() => {
        return {
            minOneUppercase:
                !v$.value.password?.minOneUppercase.$invalid && password.value,
            minLength: !v$.value.password?.minLength.$invalid && password.value,
            minOneLowercase:
                !v$.value.password?.minOneLowercase.$invalid && password.value,
            minOneNumber:
                !v$.value.password?.minOneNumber.$invalid && password.value,
        };
    });
</script>

<template>
    <div class="text-subtitle-1 text-medium-emphasis">Wachtwoord*</div>
    <v-overlay
        location-strategy="connected"
        location="top"
        scroll-strategy="reposition"
        :close-on-back="false"
        :close-on-content-click="false"
        open-on-focus
        offset="20"
        :scrim="false"
    >
        <span>
            <v-alert>
                <v-alert-title> Wachtwoord eisen</v-alert-title>
                <v-icon
                    data-cy="passwordMinLength"
                    :icon="
                        passwordErrors.minLength
                            ? 'mdi-check-circle'
                            : 'mdi-close-circle'
                    "
                    :color="passwordErrors.minLength ? 'green' : 'red'"
                />
                Minimaal {{ lengthRequirement }} tekens.
                <br />
                <v-icon
                    data-cy="passwordUpperCase"
                    :icon="
                        passwordErrors.minOneUppercase
                            ? 'mdi-check-circle'
                            : 'mdi-close-circle'
                    "
                    :color="passwordErrors.minOneUppercase ? 'green' : 'red'"
                />
                Minimaal 1 hoofdletter.
                <br />
                <v-icon
                    data-cy="passwordLowerCase"
                    :icon="
                        passwordErrors.minOneLowercase
                            ? 'mdi-check-circle'
                            : 'mdi-close-circle'
                    "
                    :color="passwordErrors.minOneLowercase ? 'green' : 'red'"
                />
                Minimaal 1 kleine letter.
                <br />
                <v-icon
                    data-cy="passwordNumeric"
                    :icon="
                        passwordErrors.minOneNumber
                            ? 'mdi-check-circle'
                            : 'mdi-close-circle'
                    "
                    :color="passwordErrors.minOneNumber ? 'green' : 'red'"
                />
                Minimaal 1 cijfer.
            </v-alert>
            <br />
        </span>

        <template #activator="{ props: activatorProps }">
            <v-text-field
                v-bind="activatorProps"
                v-model="password"
                placeholder="Wachtwoord"
                density="compact"
                variant="outlined"
                hide-details
                :type="showPassword ? 'text' : 'password'"
                :error="v$.password.$error"
                data-cy="password"
                @blur="v$.password.$touch"
            >
                <template #append-inner>
                    <v-icon
                        v-bind="activatorProps"
                        :color="showPassword ? 'primary' : 'grey'"
                        @click="showPassword = !showPassword"
                        >{{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>
                </template>
            </v-text-field>
        </template>
    </v-overlay>
    <template v-if="props.requireConfirmation">
        <br />
        <div class="text-subtitle-1 text-medium-emphasis">
            Wachtwoord bevestigen*
        </div>
        <v-text-field
            v-model="passwordConfirmation"
            placeholder="Herhaal wachtwoord"
            density="compact"
            variant="outlined"
            hide-details="auto"
            data-cy="passwordConfirmation"
            :error-messages="getErrors(v$.passwordConfirmation.$errors)"
            type="password"
            @blur="v$.passwordConfirmation.$touch"
        />
    </template>
</template>
