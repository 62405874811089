export const getSumupUrl = (
    amount: number,
    orderNumber: string,
    foreignTxId: string,
    callbackHost: string,
) => {
    return (
        "sumupmerchant://pay/1.0" +
        "?affiliate-key=" +
        import.meta.env.SUMUP_AFFILIATE_KEY +
        (import.meta.env.APP_ID ? "&app-id=" + import.meta.env.APP_ID : "") +
        "&total=" +
        amount +
        "&currency=EUR" +
        "&foreign-tx-id=ord-" +
        foreignTxId +
        "-" +
        new Date().getTime() +
        "&title=Bestelling " +
        orderNumber +
        "&skip-screen-success=true" +
        "&callback=" +
        callbackHost +
        "/orders/sumupcallback/" +
        foreignTxId
    );
};
