import { Ref, ref } from "vue";
import timeslotService, { Timeslot } from "@/services/TimeslotService";

type GenericRemoteComposableResult<T> =
    | {
          data: Ref<undefined>;
          loading: Ref<true>;
          error: Ref<true>;
          loadData: () => void;
      }
    | {
          data: Ref<T>;
          loading: Ref<false>;
          error: Ref<false>;
          loadData: () => void;
      }
    | {
          data: Ref<undefined>;
          loading: Ref<false>;
          error: Ref<true>;
          loadData: () => void;
      };

export const useTimeslots = () => {
    const data = ref<Timeslot[]>([]);
    const loading = ref<boolean>(true);
    const error = ref<boolean>(false);

    const loadData = async () => {
        loading.value = true;

        timeslotService
            .getAll()
            .then((slots) => {
                data.value = slots;
            })
            .catch(() => {
                error.value = true;
            })
            .finally(() => {
                loading.value = false;
            });
    };

    loadData();

    return { data, loading, error, loadData } as GenericRemoteComposableResult<
        Timeslot[]
    >;
};
