<script lang="ts" setup>
    import { OrderDetails } from "@/models/OrderDetails";
    import PaymentMethodChip from "@/components/common/chips/PaymentMethodChip.vue";
    import PaymentStatusChip from "@/components/common/chips/PaymentStatusChip.vue";

    const { item } = defineProps<{
        item: OrderDetails;
    }>();

    const parseDay = (day: Date) => {
        const options: Intl.DateTimeFormatOptions = {
            year: undefined,
            month: "long",
            day: "numeric",
        };
        return new Date(day).toLocaleDateString("nl-NL", options);
    };

    const parseTime = (time: string) => {
        return time.slice(0, -3);
    };
</script>

<template>
    <tr>
        <td class="pt-2" colspan="2">
            <span class="d-inline-flex flex-column pb-2">
                <span class="pt-3 mb-2 pl-2 font-weight-regular">
                    {{ item.customer.name }}
                </span>
                <span class="d-inline-flex flex-column pb-2">
                    <span>
                        <v-chip class="mr-2" label prepend-icon="mdi-calendar">
                            {{ parseDay(item.timeslot.day) }}
                        </v-chip>
                        <v-chip class="mr-2" label prepend-icon="mdi-clock">
                            {{ parseTime(item.timeslot.start) }} -
                            {{ parseTime(item.timeslot.end) }}
                        </v-chip>
                    </span>
                </span>
            </span>
        </td>
        <td v-if="item.deliver">
            <v-chip class="mr-2" label>{{ item.fulfillment.area.name }}</v-chip>

            <v-chip class="mr-2" label>
                {{ item.fulfillment.areaGroup.name }}
            </v-chip>
        </td>
        <td v-else>
            <v-chip class="mr-2" label>{{ item.fulfillment.label }}</v-chip>
        </td>
        <td>
            <span class="d-inline-flex flex-column pb-2">
                <span class="mb-2">
                    <template v-for="product in item.products">
                        <v-chip
                            v-if="product.amount > 0"
                            :key="product.product.id"
                            class="mr-2"
                            label
                        >
                            {{ product.product.label }}
                        </v-chip>
                    </template>
                </span>
            </span>
        </td>
        <td>
            <span>
                <span class="mr-2">
                    <payment-method-chip
                        :method="item.paymentMethod"
                        class="mr-2"
                    />
                </span>
                <payment-status-chip :status="item.paymentStatus" />
            </span>
        </td>
    </tr>
</template>

<style scoped></style>
