<script lang="ts" setup>
    import { required } from "@/composables/dutchVuelidate";
    import { getVuelidateErrors } from "@/composables/errorHandler";
    import authService, {
        GlobyInstance,
    } from "@/services/AuthenticationService";
    import { useInstanceStore } from "@/store/instance";
    import useVuelidate from "@vuelidate/core";
    import { onMounted, ref } from "vue";
    import { useRoute, useRouter } from "vue-router";
    import { useDisplay } from "vuetify";
    import { VAlert } from "vuetify/components";

    const { smAndDown } = useDisplay();

    const selected = ref();

    const availableActions = ref<GlobyInstance[]>([]);

    const notification = ref("");

    onMounted(async () => {
        const result = [];

        if (Object.create(globalThis.import_meta_env || null).APP_ENV === "DEV") {
            result.push({
                ApiUrl: "http://localhost:8000",
                CanonicalName: "Lokale host (development)",
            } as GlobyInstance);
        }

        // Load from Nexus
        try {
            const instances = (await authService.getInstances()).data;
            instances.forEach((instance) => {
                result.push(instance);
            });
        } catch (e) {
            // Some error occurred, notify the user.
            notification.value = "Kan de beschikbare acties niet ophalen ...";
        } finally {
            availableActions.value = result;

            selected.value = result[0];
        }
    });

    const v$ = useVuelidate(
        {
            selected: { required },
        },
        {
            selected,
        },
    );

    const instanceStore = useInstanceStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref();
    const submit = async () => {
        loading.value = true;

        if (!(await v$.value.$validate())) {
            loading.value = false;
            return;
        }

        const selectedInstance = selected.value;
        instanceStore.apiUrl = selectedInstance.ApiUrl;
        instanceStore.name = selectedInstance.CanonicalName;

        await router.push({ name: "login", query: route.query });

        loading.value = false;
    };
</script>

<template>
    <v-expand-transition>
        <v-alert
            v-show="notification !== ''"
            class="mb-5"
            theme="light"
            data-cy="alert"
            type="error"
            :max-width="smAndDown ? undefined : 500"
        >
            {{ notification }}
        </v-alert>
    </v-expand-transition>
    <v-card class="rounded-lg" :width="smAndDown ? undefined : 500">
        <v-carousel height="200px" :show-arrows="false" :hide-delimiters="true">
            <v-carousel-item cover src="src/assets/authenticationCover.png" />
        </v-carousel>
        <v-card-title>Kies een actie</v-card-title>
        <v-card-text>
            <v-form>
                <div class="text-subtitle-1 text-medium-emphasis">
                    Kies de actie waarmee je aan de slag wilt
                </div>
                <v-select
                    v-model="selected"
                    data-cy="instanceSelect"
                    :items="availableActions"
                    item-title="CanonicalName"
                    return-object
                    hide-details="auto"
                    :error-messages="getVuelidateErrors(v$.selected.$errors)"
                    variant="outlined"
                    density="compact"
                    @focusout="v$.selected.$validate"
                />
            </v-form>
            <br />
            <v-row>
                <v-spacer />
                <v-col class="flex-grow-0">
                    <v-btn
                        color="primary"
                        variant="flat"
                        :loading="loading"
                        data-cy="submit"
                        @click="submit"
                        >Inloggen
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
