import { OrderDetails } from "@/models/OrderDetails";
import { computed, ComputedRef, MaybeRef, unref } from "vue";
import {
    isPaymentSupported,
    startPayment,
} from "@/services/PinServices/PinTerminalBridge";

export type PinTerminal = {
    isSupported: ComputedRef<boolean>;
    startPayment: () => Promise<void>;
};

export const usePinTerminal = (
    order: MaybeRef<OrderDetails | undefined>,
): PinTerminal => {
    const isSupported = computed(() => {
        const rawOrder = unref(order);
        if (!rawOrder) {
            return false; // undefined orders are always unsupported
        }
        return isPaymentSupported(rawOrder);
    });

    return {
        isSupported,
        startPayment: () => {
            const rawOrder = unref(order);
            if (!rawOrder) {
                console.error("Order is not defined");
                return Promise.reject("Order is not defined");
            }
            return startPayment(rawOrder);
        },
    };
};
